ACC.cookie = {

 getCookie:function(cookieName){
    const cookie= `${cookieName}=`;
    const decodeCookie=decodeURIComponent(document.cookie);
    const cookieArray=decodeCookie.split(';');
        for(let i=0; i<cookieArray.length;i+=1){
            let cookieObj=cookieArray[i];
            while(cookieObj.charAt(0)===' '){
            cookieObj=cookieObj.substring(1);
            }
            if(cookieObj.indexOf(cookieName)===0){
                return cookieObj.substring(cookie.length,cookieObj.length);
            }
        }
    },

    getMatchingCookies:function(cookieName){
        let matchingCookies= document.cookie.split(';').filter(c => c.trim().startsWith(cookieName));
        return matchingCookies;
    },

     getFormattedCookies: function(marketingCookies){
        let marketingCookieObject='{'
         if(marketingCookieObject.length>0){
            for(let i=0; i<marketingCookies.length;i+=1){
             let cookieObj=marketingCookies[i].trim();
                var cookieName=cookieObj.substring(0,cookieObj.indexOf('='));
                if(i===0){
                marketingCookieObject=marketingCookieObject+cookieName+":";
                }
                else{
                marketingCookieObject=marketingCookieObject+","+cookieName+":";
                }

                var cookieValue=cookieObj.substring(cookieObj.indexOf('=')+1,cookieObj.length)
                marketingCookieObject=marketingCookieObject+cookieValue;
             }
             var leadDasrc=ACC.cookie.getCookie(ACC.config.acimaDasrcCookie);
             var leadDavuid=ACC.cookie.getCookie(ACC.config.acimaDavuidCookie);
             marketingCookieObject=marketingCookieObject+',davuid:'+leadDavuid+',dasrc:'+leadDasrc+"}";
         }
         else{
              marketingCookieObject+'davuid:'+leadDavuid+',dasrc:'+leadDasrc+"}"
         }
             return marketingCookieObject;

       }

}