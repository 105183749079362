ACC.common = {
	currentCurrency: $("main").data('currencyIsoCode') || "USD",
	processingMessage: $("<img>").attr("src", ACC.config.commonResourcePath + "/images/spinner.gif"),


	blockFormAndShowProcessingMessage: function (submitButton) {
		var form = submitButton.parents('form:first');
		form.block({ message: ACC.common.processingMessage });
	},

	refreshScreenReaderBuffer: function () {
		// changes a value in a hidden form field in order
		// to trigger a buffer update in a screen reader
		$('#accesibility_refreshScreenReaderBufferField').attr('value', new Date().getTime());
	},

	checkAuthenticationStatusBeforeAction: function (actionCallback) {
		$.ajax({
			url: ACC.config.authenticationStatusUrl,
			statusCode: {
				401: function () {
					location.href = ACC.config.loginUrl;
				}
			},
			dataType: 'json',
			success: function (data) {
				if (data == "authenticated") {
					actionCallback();
				}
			}
		});
	},

	encodeHtml: function (rawText) {
		return rawText.toString()
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#39;')
			.replace(/\//g, '&#47;');
	}
};

$(document).ready(function () {
	
	let marketingCookies = null;
	if (!isNullUndefined(ACC.cookie)) {
	   marketingCookies = ACC.cookie.getFormattedCookies(ACC.cookie.getMatchingCookies('utm_'));
	}
	let davuid = null;
	let dasrc = 'web';
	if (!isNullUndefined(marketingCookies)) {
	   marketingCookies = marketingCookies.slice(1, -1);
	   const keyValuePairs = marketingCookies.split(',');
	   keyValuePairs.forEach(keyValuePair => {
	      const [key, value] = keyValuePair.split(':');
	      if (!isNullUndefined(key)) {
	         if (key == 'davuid') {
	            davuid = value;
	         }
	         if (key == 'dasrc') {
	            dasrc = value;
	         }
	      }
	   });
	} else {
	   if (!isNullUndefined($.cookie) && !isNullUndefined($.cookie("davuid"))) {
	      davuid = $.cookie("davuid")
	   }
	   if (!isNullUndefined($.cookie) && !isNullUndefined($.cookie("dasrc"))) {
	      dasrc = $.cookie("dasrc")
	   }
	}
	window.dataLayer.push({
	   'event': "digitalAnalyticsParams",
	   'davuid': davuid,
	   'dasrc': dasrc
	});
	
	if (isElemExist("#js-site-search-input")) {
		if ($("#js-site-search-input").val().length > 0) {
			$(".PopularSearchesContainer").addClass("d-none");
		}
		else {
			$(".PopularSearchesContainer").removeClass("d-none");
		}
		$("#js-site-search-input").on("input", function () {
			$("#products").contents().remove();
			$("#suggesstion").contents().remove();
		})
	}
	$("#globalSearchDismiss,.site-search .cross-sign").click(function () {
		$("#js-site-search-input").val('');
		$("#products").contents().remove();
		$("#suggesstion").contents().remove();
		$("#SuggestionContainerId").addClass("d-none");
		$("#ProductContainerId").addClass("d-none");
		$(".site-search .cross-sign").addClass('d-none');
	})
	$('#SearchModel').on('shown.bs.modal', function () {
		$('#js-site-search-input').focus()
	})
	// Search Modal Toggler
	$('#globalSearchBtn').on("click", function () {
		$(window).scrollTop(0);
		updateSearchModal()
	});

	$('.stronModalCloseBtn').on("click", function () {
			var sessionZipcode = $('.sessionZipcode').val();
			if (!$('#notAvilableError').hasClass('d-none') || !$('#locNotFound').hasClass('d-none') || !$('#invalidZipError').hasClass('d-none')) {
				if(!!sessionZipcode){
					$("#enteredZipCode").val(sessionZipcode);
				}
				if(isAcimaSite()){
					$('#zipErrorForChange').css({ "display": "none" });
				}
				else {
					$('#zipErrorForChange').css({ "display": "block" });
				}
				$("#redirectToVendorSide").css({ "display": "none" });
				$("#enterZipCodeGoButton").removeAttr('style')

				$('#notAvilableError, #locNotFound, #invalidZipError').addClass('d-none');
			}
		}
	)
});


/* Extend jquery with a postJSON method */
jQuery.extend({
	postJSON: function (url, data, callback) {
		return jQuery.post(url, data, callback, "json");
	}
});

// add a CSRF request token to POST ajax request if its not available
$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
	// Modify options, control originalOptions, store jqXHR, etc
	if (options.type === "post" || options.type === "POST") {
		var noData = (typeof options.data === "undefined");
		if (noData) {
			options.data = "CSRFToken=" + ACC.config.CSRFToken;
		}
		else {
			var patt1 = /application\/json/i;
			if (options.data instanceof window.FormData) {
				options.data.append("CSRFToken", ACC.config.CSRFToken);
			}
			// if its a json post, then append CSRF to the header.
			else if (patt1.test(options.contentType)) {
				jqXHR.setRequestHeader('CSRFToken', ACC.config.CSRFToken);
			}
			else if (options.data.indexOf("CSRFToken") === -1) {
				options.data = options.data + "&" + "CSRFToken=" + ACC.config.CSRFToken;
			}
		}

	}
});

// Global Spinner Global Functions
window.ACIMA = window.ACIMA || [];
ACIMA.loadingSpinnerShow = function () {
	jQuery('.full-page-spinner').show();
}
ACIMA.loadingSpinnerHide = function () {
	jQuery('.full-page-spinner').hide();
}

// Category Header Scripts
function updateCategoryContainer() {
	const CategoryContainer = $(".js-mainHeader .header-category__nav");
	if (isElemExist(CategoryContainer)) {
		const distanceFromTop = CategoryContainer.offset().top;
		const screenWidth = $(window).width();
		if (screenWidth >= 1023) {
			CategoryContainer.css("height", `calc(80vh - ${distanceFromTop}px)`);
		} else {
			CategoryContainer.css("height", `calc(${window.innerHeight}px - ${distanceFromTop}px)`);
		}
	}
}

$('.header-category').on("click", function () {
	$(this).toggleClass('active');
	$('.header-category__nav').toggleClass('hide').scrollTop(0);
	if ($(this).hasClass('active')) {
		$('body').addClass('body-fixed category-overlay');
		$('header').addClass('position-relative');
	}
	else {
		$('body').removeClass('body-fixed category-overlay');
		$('header').removeClass('position-relative');
	}
	updateCategoryContainer();
});

$('.header-category__nav__close, #globalSearchBtn').on("click", function () {
	$('.header-category__nav').addClass('hide');
	$('.header-category').removeClass('active');
	$('body').removeClass('body-fixed category-overlay');
	$('header').removeClass('position-relative');
});

// Updating CategoryContainer Size
$(window).on('resize', function () {
	updateCategoryContainer();
});

// Desktop top header dropdown js
$(document).ready(function () {
	$('.top-header-desktop .dropdown-title').click(function () {
		$(this).siblings('.dropdown-sub-menu').toggleClass("show");
		$('.top-header-desktop .dropdown-sub-menu').not($(this).siblings()).removeClass("show");
		$(this).toggleClass("active");
		$('.top-header-desktop .dropdown-title').not($(this)).removeClass("active");
	});

	const topDesktopHeader = $(".top-header-desktop");
	if (isElemExist(topDesktopHeader)) {
		$(document).on("click", function (e) {
			if (!$(e.target).is(".top-header-desktop .dropdown-list *")) {
				$(".top-header-desktop .dropdown-sub-menu").removeClass("show");
				$(".top-header-desktop .dropdown-title").removeClass("active");
			}
		});
	}
	
	$('.acima-Leasing-image-component').click(function() {
	   loginEvent('acima');
	});
	
	$('.acima-acceptance-now-logo-image-component').click(function() {
	   loginEvent('acceptanceNow');
	});
	
	$('.acima-privatecredit-image-component').click(function() {
	   loginEvent('acimaPrivateCredit');
	});
	
	$('.acima-classiccredit-image-component').click(function() {
	   loginEvent('acimaClassicCredit');
	});		

	function loginEvent(leaseProvider) {
	   window.dataLayer.push({
	      'event': "login",
	      'leaseProvider': leaseProvider
	   });
	}
	
	$('.mobile-app-click-button').click(function() {
	   var app = 'iOS';
	   var url = $(this).attr('href');
	   if (!isNullUndefined(url)) {
	      if (url.includes('play.google.com')) {
	         app = 'android';
	      }
	   }
	   window.dataLayer.push({
	      'event': "getApp",
	      'appPlatform': app
	   });
	});
	
	$('.mkt-faq-click').click(function() {
	   var event = 'creditFaqClick';
	   var eventName = $(this).attr('data-page');
	   if (!isNullUndefined(eventName) && (eventName == 'acimaMarketplacePage' || eventName == 'acimaShopOnlinePage')) {
	      event = 'mktFaqClick';
	   }
	   var faqName = $(this).attr('data-value');
	   window.dataLayer.push({
	      'event': event,
	      'faqName': faqName
	   });
	});
})
// Zipcode Modal 
$(document).ready(function () {
	var pageType = $('.pageTypeValue').val();
	var storeValue = $('.storeValue').val();
	var sessionZipcode = $('.sessionZipcode').val();
	var currentSessionZipCode = $('#currentSessionZipCode').attr('data-value');
	setStoreLocatorPrompt();
	if (pageType != undefined && (pageType == 'CATEGORY' || pageType == 'PRODUCT' ||
		pageType == 'PRODUCTSEARCH' || pageType == 'SEARCH' || pageType == 'SHOP' || pageType == 'CART') && (storeValue == undefined || storeValue == null || storeValue == '')
		&& (sessionZipcode == undefined || sessionZipcode == null || sessionZipcode == '')) {
		var closeButton = $('#StoreNumberModal button.close');
		closeButton.attr('style', 'display: none !important');
		$('#StoreNumberModal').modal({ backdrop: 'static', keyboard: false }, 'show');
	}
	else if (isNullUndefined(currentSessionZipCode) && isAcimaSite() && showStoreLocatorPopup()) {
    	useMyLocation(true);
    }

	$('#enterZipCodeGoButton').click(function (e) {
		e.preventDefault();
		checkZipCode();
	});

	$('#enteredZipCode').on('keyup', function (event) {
		if (event.which === 13) {
			checkZipCode();
		}
		$('#notAvilableError, #locNotFound, #invalidZipError').addClass('d-none');
		// $('#zipErrorForChange').css({ "display": "none" });
	});

	function getAddressByZipCode(zipCode) {

		$.ajax({
			url: ACC.config.encodedContextPath + '/store-finder/addressByZipCode/' + zipCode,
			type: "GET",
			success: function(data) {
			}, error: function() {

			}
		});
	}

	function getAddressByLocation() {

		$.ajax({
			url: ACC.config.encodedContextPath + '/store-finder/getformattedAddressForLocation',
			data: {
				location: "Durham, NC, 27713"
			},
			type: "GET",
			success: function(data) {
			}, error: function() {

			}
		});
	}

	function getAddressByGeocodes(latitude, longitude) {

		$.ajax({
			url: ACC.config.encodedContextPath + '/store-finder/getAddressByGeocodes',
			data: {
				latitude :35.98969,
				longitude :-78.90515
			},
			type: "GET",
			success: function(data) {
				console.log(data);
			}, error: function() {

			}
		});
	}

	function checkZipCode() {
		$zipCodeSpanError = $("#zipError");
		var $zipCodeFieldID = $("#enteredZipCode");
		if (validateHeaderZipCode()) {
			var zipCodeValue = $("#enteredZipCode").val();
			var currentUrl = window.location.href;
			if (!isNullUndefined(currentUrl) && (currentUrl.includes('marketplace') || currentUrl.includes('shoponline'))) {
			   window.dataLayer.push({
			      'event': "mktZipSearch",
			      'zipCode': zipCodeValue
			   });
			}
									
			if (isTrue($(".externalAPIVendor").val()) || isAcimaSite()) {
            	url = ACC.config.encodedContextPath + '/store-finder/setZipCode';
            } else {
            	url = ACC.config.encodedContextPath + '/store-finder/setStore';
            }
			$.ajax({
				url: url,
				data: {
					enteredZipCode: $zipCodeFieldID.val()
				},
				type: "GET",
				beforeSend:function (){
					$('.full-page-spinner').show();
				},
				success: function (data) {
					if (!isNullUndefined(data)) {
						if (isAcimaSite()) {
                        	handleSetZipCodeResponse($zipCodeFieldID.val());
                        } else {
                        	if (!isNullUndefined($('.pageTypeValue').val()) && $('.pageTypeValue').val() == 'HOME') {
                        		window.location = ACC.config.encodedContextPath + '/shop';
                        	} else {
                        		location.reload(true);
                        	}
                        }
					} else {
						$('.full-page-spinner').hide();
						$('#invalidZipError').addClass('d-none');
						$('#notAvilableError').removeClass('d-none');
						$('#zipErrorForChange').css({ "display": "none" });
						setInvalidZipCodeMessage();
						if (!isNullUndefined($('.vendorUrl').val())) {
							$("#enterZipCodeGoButton").hide();
							$("#redirectToVendorSide").css({ "display": "block" });
						}
					}

					// $('#enterZipCodeGoButton').removeClass('set-btn-loading');
				},
				error: function (ex) {
					console.log("Error setting Store");
					$('#enterZipCodeGoButton').attr('disabled', false);
				},
				complete: function (){

				}
			});
		} else {
			$('#invalidZipError').removeClass('d-none');
			$('#enterZipCodeGoButton').attr('disabled', false);
		}
	}


	$('.use-my-location').on('click', function () {
		useMyLocation(false);
	})

	function useMyLocation(showZipCodePopup) {
		jQuery('.full-page-spinner').show();
		if (navigator.geolocation) {
			$('#locNotFound').addClass('d-none');
			$('#notAvilableError').addClass('d-none');
			navigator.geolocation.getCurrentPosition(
				function(successCallback) {
					setNearbyStore(successCallback, showZipCodePopup);
				},
				function(errorCallback) {
					if (errorCallback && errorCallback.message && errorCallback.message.includes('denied')) {
                    	if (isFalse(showZipCodePopup)) {
                    		$('#locNotFound').removeClass('d-none');
                    	}
                    	jQuery('.full-page-spinner').hide();
                    }
					console.log(errorCallback)
				},
			);
		}
	}

	function setNearbyStore(position, showZipCodePopup) {
		var curLatitude = position.coords.latitude;
		var curLongitude = position.coords.longitude;
		var url ;
		if (isTrue($(".externalAPIVendor").val()) || isAcimaSite()) {
        	url = ACC.config.encodedContextPath + '/store-finder/setZipCode';
        } else {
        	url = ACC.config.encodedContextPath + '/store-finder/setStore';
        }
		$.ajax({
			url: url,
			data: {
				latitude: curLatitude,
				longitude: curLongitude
			},
			success: function (data) {
				if (!isNullUndefined(data)) {
					if (isAcimaSite()) {
                    	location.reload(true);
                    } else {
                    	if (!isNullUndefined($('.pageTypeValue').val()) && $('.pageTypeValue').val() == 'HOME') {
                    		window.location = ACC.config.encodedContextPath + '/shop';
                    	} else {
                    		location.reload(true);
                    	}
                    }
				}
				else {
                 	if (isTrue(showZipCodePopup)) {
                 		$('#StoreNumberModal').modal('show');
                 	} else {
                 		$('#notAvilableError').removeClass('d-none');
                 	}
                 	setInvalidZipCodeMessage();
                }
			},
			error: function (error) {
				console.log(error); //server error
			},
			complete: () => {
				jQuery('.full-page-spinner').hide();
			}
		});
	}


	$("#shopNowButton").click(function () {
		var sessionZipcode = $('.sessionZipcode').val();
		if (sessionZipcode == undefined || sessionZipcode == null || sessionZipcode == '') {
			$("#enteredZipCode").val(sessionZipcode);
			$('#StoreNumberModal').modal('show');
		} else {
			window.location = ACC.config.encodedContextPath + '/shop';
		}
	});

	// Mobile Header & Sidebar js
	$('.sidebar-mobile .dropdown-title').click(function () {
		$(this).siblings('.dropdown-sub-menu').toggleClass("show");
		$(this).toggleClass("active");
	});
	const menuOpenBtn = $(".mobile-nav-click");
	const sidebarMenu = $("#mobile-menu-sidebar");
	$(menuOpenBtn).click(function () {
		$(sidebarMenu).css("left", "0");
		$("body").addClass("sidebar-overlay");
	});
	// Closing Sidebar
	if (isElemExist(sidebarMenu)) {
		$(document).on("click", function (e) {
			if ($("body").hasClass("sidebar-overlay") && menuOpenBtn.has(e.target).length === 0) {
				if (!sidebarMenu.is(e.target) && sidebarMenu.has(e.target).length === 0) {
					sidebarMenu.css("left", "-100%");
					$("body").removeClass("sidebar-overlay");
					$(".sidebar-mobile .dropdown-sub-menu").removeClass("show");
					$(".sidebar-mobile .dropdown-title").removeClass("active");
				}
			}
		});
	}
	// Mobile Nav header dropdown js
	$('.header-nav-user .login-user-logo').click(function () {
		$('.header-user-dropdown .dropdown-sub-menu').toggleClass("show");
	});
	const mobileNavHeader = $(".header-nav-user");
	if (isElemExist(mobileNavHeader)) {
		$(document).on("click", function (e) {
			if (!$(e.target).is(".header-nav-user *")) {
				$(".header-user-dropdown .dropdown-sub-menu").removeClass("show");
			}
		});
	}

	// when user will click on change zip code modal then below code will update the input field accordingly
	var sessionZipcode = $('.sessionZipcode').val();
	if (sessionZipcode) {
		$("#enteredZipCode").val(sessionZipcode);
		$("#zipErrorForChange").removeAttr('style');
	}

	$('#StoreNumberModal').on('shown.bs.modal', function () {
		$('#enteredZipCode').focus();
	});
})

//JS for Search Modal
function updateSearchModal() {
	const searchModalContentTop = $('.searchModalContainer .modal-content');
	const searchModalTop = $('.searchModalContainer .modal');
	const headerBotttom = $("header .header-bottom");
	if (isElemExist(headerBotttom)) {
		const headerHeight = $("header").height();
		const headerBotttomHeight = $("header .header-bottom").height();
		const headerBreadcrumb = $("header .breadcrumb-section");
		let headerBreadcrumbHeight = 0;
		let storeLocatorHeader = $(".shopping-store-wrapper");
		let storeLocatorHeaderHeight = 0
		if (isElemExist(headerBreadcrumb)) {
			headerBreadcrumbHeight = $("header .breadcrumb-section").height();
		}
		else {
			headerBreadcrumbHeight = 0;
		}
		if (isElemExist(storeLocatorHeader)) {
			storeLocatorHeaderHeight = storeLocatorHeader.innerHeight();
		}
		else {
			storeLocatorHeaderHeight = 0
		}
		const calcDistanceFromTop = (headerHeight - headerBotttomHeight - headerBreadcrumbHeight + storeLocatorHeaderHeight);
		const screenWidth = $(window).width();
		if (screenWidth >= 1023) {
			searchModalTop.css("top", calcDistanceFromTop);
			searchModalContentTop.css("height", `calc(80vh - ${calcDistanceFromTop}px)`);
			if ($('body').hasClass('page-theme-amazon')) {
				searchModalContentTop.css("height", 'auto');
			}
		} else {
			if ($('body').hasClass('page-theme-amazon')) {
				let headerNavMobile = $(".header-nav-mobile");
				let headerNavMobileHeight = 0
				if (isElemExist(headerNavMobile)) {
					headerNavMobileHeight = headerNavMobile.innerHeight();
				}
				let headerTopCart = $(".header-top-cart");
				let headerTopCartHeight = 0;
				if (isElemExist(headerTopCart)) {
					headerTopCartHeight = headerTopCart.height();
				}
				let searchTopDistanceMobile = storeLocatorHeaderHeight + headerNavMobileHeight + headerTopCartHeight;
				searchModalTop.css("top", searchTopDistanceMobile);
			}
			else {
				searchModalTop.css("top", 0);
				searchModalContentTop.css("height", '100vh');
			}
		}
	}
}

// Updating SearchModal Size
$(window).on('resize', function () {
	updateSearchModal();
});

// Generic Utility Functions
function isElemExist(elem) {
	return $(elem) !== null && $(elem) !== undefined && $(elem).length > 0;
}
function isNullUndefined(val) {
	return typeof val == 'string' && !val.trim() || val === undefined || val === null;
}
function isTrue(val) {
	return (val !== null || val !== undefined) && val !== '' && (val === true || val === 'true');
}
function isFalse(val) {
	return (val !== null || val !== undefined) && val !== '' && (val === false || val === 'false');
}
function isNotTrue(val) {
	return val !== true ? true : false;
}
function isNotFalse(val) {
	return val !== false ? true : false;
}
function isMobileDevice() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || $(window).width() < 768;
}

function  isAndroidDevice(){
	return /android/i.test(navigator.userAgent) ;
}

function isIOSDevice(){
	return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

function isAcimaSite() {
	return isTrue($('#isAcimaSite').attr('data-value'));
}

$('#timeoutPopupModal').on('shown.bs.modal', function (e) {
	setTimeout(function () {
		window.location.href = ACC.config.encodedContextPath + '/invalidateSession';
	}, $('#no_activity_timeout_timer').val());
})
setTimeout(function () {
	$('#timeoutPopupModal').modal('show');
}, $('#timeout_modal_timer').val());

$('#moreTimeReq').on('click', function () {
	$.ajax({
		url: ACC.config.encodedContextPath + '/ping',
		type: "GET",
		success: function (data) {
			$('#timeoutPopupModal').modal('hide');
		},
		error: function (ex) {
			console.log("Failed to ping server");
		}
	});
});

// Generic method to show error alerts
function showGlobalErrorMsg(imgName, msg) {
	var newMsg = msg.trim();
	var existingMsgs = $(".alert-cart-container #ajaxErrorMsg").map(function() {
		return $(this).text().trim();
	}).get();
	if (existingMsgs.indexOf(newMsg) === -1) {
		let newErrorMsg = $(".alert-cart-container.ajax-error").eq(0).clone();
		let imgBasePath = $(".alert-cart-container.ajax-error").eq(0).find("#errorMsgImg").attr("data-src");
		$(".alert-cart-container").last().after(newErrorMsg);
		$(".alert-cart-container").last().find("#errorMsgImg").attr("src", imgBasePath + imgName);
		$(".alert-cart-container").last().find("#ajaxErrorMsg").html(newMsg);
		$(".alert-cart-container").last().removeClass("hidden");
	}
}

// Function to load JS Scripts on demand but once
var loadedScriptsArray = [];
function loadScriptOnDemand(url, callback) {
	if (loadedScriptsArray.indexOf(url) === -1) {
		if (typeof callback === 'function') {
			return $.getScript(url, function (script, textStatus, jqXHR) {
				loadedScriptsArray.push(url);
				callback(script, textStatus, jqXHR);
			});
		} else {
			return $.getScript(url, function () {
				loadedScriptsArray.push(url);
			});
		}
	} else {
		return {
			done: function () {
				return {
					fail: function () { }
				};
			}
		};
	}
}
$(window).on('load', function () {
	setTimeout(function () {
		const deviceDetected = $('#isMobileAppRequest').attr('data-value');
		if (!isNullUndefined(deviceDetected) && isFalse(deviceDetected)) {
			let chatParamsarray = $('#chatInitParams').attr('data-value');
			chatParamsarray = chatParamsarray.split(',');
			loadScriptOnDemand($('#chatEmbedUrl').attr('data-value'), function () {
				icPatronChat.init({
					serverHost: $('#chatInitServerHost').attr('data-value'),
					bus_no: Number($('#chatInitBusNo').attr('data-value')),
					poc: $('#chatInitPoc').attr('data-value'),
					params: chatParamsarray
				});
			});
		}
	}, Number($('#chatDelayTime').attr('data-value')));
});



function validateHeaderZipCode() {
	var zipCodeChk = $('#enteredZipCode').val();
	var zipCodeRegex = /^\d{5}$/;
	if (zipCodeRegex.test(zipCodeChk)) {
		return true;
	} else {
		return false;
	}
}

//Function is called from the store locator modal input.
function handlestorelocatorInput(currentValue) {
	if ($('#notAvilableError').is(':visible')) {
		if (currentValue.length < 5) {
			$("#enterZipCodeGoButton").css({ "display": "" });
			$("#redirectToVendorSide").css({ "display": "none" });
			$("#notAvilableError").addClass('d-none');
		}
	}
}
//Code to hide Search Modal On Document Click
//Code to hide Search Modal On Document Click
$(document).on("click", function (e) {
	const headerBottom = $(".header-bottom");
	if (isElemExist(headerBottom)) {
		if (!$(e.target).is(".header-bottom *")) {
			$("#SearchModel").modal("hide");
		}
	}
	const marketplaceModals = $("#marketPlaceDropdown");
	if (isElemExist(marketplaceModals) && $("#marketPlaceDropdown").hasClass('in')) {
		$("#marketPlaceDropdown").modal("hide");
	}
});

// type inputType = "chat" | "tel"
function externalCallLink(type, input, thisAttr) {
    const isMobileAppRequest = $('#isMobileAppRequest').attr('data-value');
	const isMAUIPlatform = $('#isMAUIPlatform').attr('data-value');
	if (isTrue(isMobileAppRequest)) {
    	if (isTrue(isMAUIPlatform)) {
    		if (type === "chat") {
    			if (isTrue(isAndroidDevice())) {
    				invokeActionInCS(`CL|${input}`)
    			} else if (isTrue(isIOSDevice())) {
    				invokeCSharpAction(`CL|${input}`)
    			}
    		}
    		else {
    			if (isTrue(isAndroidDevice())) {
    				invokeActionInCS(`PN|${input}`)
    			} else if (isTrue(isIOSDevice())) {
    				invokeCSharpAction(`PN|${input}`)
    			}
    		}
    	} else {
    		type === "chat" ? invokeCSharpAction(`CL|${input}`) : invokeCSharpAction(`PN|${input}`);
    	}
    } else {
    	type === "chat" ? $(thisAttr).attr('href', input).attr('target', '_blank') : $(thisAttr).attr('href', `tel:${input.replaceAll('-', '')}`).attr('target', '_self');
    }
}

function mobileToAppRequestFn(input) {
	if (isTrue(isAndroidDevice())) {
		invokeActionInCS(input);
	} else if (isTrue(isIOSDevice())) {
		invokeCSharpAction(input);
	}
}

$(document).ready(function () {
	$("#goShoppingBtn").on("click", function () {
		window.location.href = ACC.config.encodedContextPath + '/shop';
	});
})

//ACIMA STICKY HEADER
$(document).ready(function () {
	function updateAcimaHeader() {
		if($('body').hasClass('page-theme-acima')) {
			const topHeaderDesktop = $('.top-header-desktop');
			const topAboutHeaderHeight = $('.top-right-header-content').outerHeight();
			if (isElemExist(topHeaderDesktop)) {
				$(".top-header-content").css('top', topAboutHeaderHeight);
				$(window).scroll(function(){
					const stickyHeaderHeight = $('.top-header-content').outerHeight();
					const headerDesktopHeight = stickyHeaderHeight + topAboutHeaderHeight;
					const screenWidth = $(window).width();
					if (screenWidth > 990 && $(window).scrollTop() >= headerDesktopHeight) {
						$('.top-header-content').addClass('fixed-header');
					}
					else {
						$('.top-header-content').removeClass('fixed-header');
					}
				});
			}
		}
	}
	updateAcimaHeader();
	// Updating on Resizing
	$(window).on('resize', function () {
		updateAcimaHeader();
	});
});

// AOS Animation
$(document).ready(function () {
	const siteAcima = $(".page-theme-acima");
	if (isElemExist(siteAcima)) {
		AOS.init({
			duration: 750,
			once: true,
			offset: 50,
		});
	}
});

async function getAddressByZipCode(zipCode) {

	return $.ajax({
		url: ACC.config.encodedContextPath + '/store-finder/addressByZipCode/' + zipCode,
		type: "GET",
		success: function (data) {
		}, error: function () {

		}
	});
}

async function getAddressByLocation(address) {
    try {
        let data = await $.ajax({
            url: ACC.config.encodedContextPath + '/store-finder/getAddressForLocation',
		data: {
			location: address
		},
		type: "GET",
        });
        return data;
    } catch (error) {
        console.error('Error fetching stores:', error);
        throw error;
    }
}

// Customer Login delay before navigating
$(document).ready(function () {
	const siteAcima = $("#concoracredit");
	if (isElemExist(siteAcima)) {
		$('#concoracredit').click(function(){
			const self = $(this).data('title');
			setTimeout(() => {
				window.location.href = self;
			}, 3000);
		});
	}
	if (isElemExist($("#acimacard"))) {
		$('#acimacard').click(function(){
			const self = $(this).data('title');
			setTimeout(() => {
				window.location.href = self;
			}, 3000);
		});
	}

	$('#partner-zip').on('input', function(e) {
        var inputValue = $(this).val();
                
        // Limit to 5 digits
        numericValue = inputValue.substring(0, 5);
        
        $(this).val(numericValue);
    });
	
	// Partner Page form mobile validation
	$('#partner-phone-number').on('input', function(e) {
        var inputValue = $(this).val();
        
        // Remove all non-numeric characters from the input value
        var numericValue = inputValue.replace(/\D/g,'');
        
        // Limit to 10 digits
        numericValue = numericValue.substring(0, 10);
        
        var formattedValue = numericValue.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/, function(match, p1, p2, p3) {
            var part1 = p1 ? '(' + p1 : '';
            var part2 = p2 ? ') ' + p2 : '';
            var part3 = p3 ? '-' + p3 : '';
            return part1 + part2 + part3;
        });
        
        $(this).val(formattedValue);
    });
});

// Print button
$(document).ready(function () {
	const printBtn = $(".case-study-print-button");
	if (isElemExist(printBtn)) {
		$(printBtn).click(function(){
			window.print();
		});
	}
});

function setStoreLocatorPrompt() {
	const pageUID = $('#acimaMpPageUID').attr('data-value');
	var storeLocatorPrompt = $('#storeLocatorPrompt');
	if (pageUID === "acimaMarketplacePage" || pageUID === "acimaShopOnlinePage" || pageUID === "acimaMarketplaceDetailsPage" || pageUID === "acimaShopOnlineDetailsPage") {
		var mpPageStoreLocatorPrompt = $('#mpPageStoreLocatorPrompt').attr('data-value');
		storeLocatorPrompt.text(mpPageStoreLocatorPrompt);
	} else if (pageUID === "acimaFindAStorePage") {
		var fasPageStoreLocatorPrompt = $('#fasPageStoreLocatorPrompt').attr('data-value');
		storeLocatorPrompt.text(fasPageStoreLocatorPrompt);
	}
}

function setInvalidZipCodeMessage() {
	const pageUID = $('#acimaMpPageUID').attr('data-value');
	var zipError = $("#zipError")
	if (pageUID === "acimaMarketplacePage" || pageUID === "acimaShopOnlinePage" || pageUID === "acimaMarketplaceDetailsPage" || pageUID === "acimaShopOnlineDetailsPage") {
		var mpPageInvalidZipMsg = $('#mpPageInvalidZipMsg').attr('data-value');
		zipError.text(mpPageInvalidZipMsg);
	} else if (pageUID === "acimaFindAStorePage") {
		var fasPageInvalidZipMsg = $('#fasPageInvalidZipMsg').attr('data-value');
		zipError.text(fasPageInvalidZipMsg);
	}
}

function handleSetZipCodeResponse(zipCode) {
	const pageUID = $('#acimaMpPageUID').attr('data-value');
	const setLocationPromptL1 = $(".set-location-prompt-l1");
	const setLocationPromptL2 = $(".set-location-prompt-l2");
	var shoppingAt = $('#shoppingAtSpan').attr('data-value');
	if (pageUID === "acimaFindAStorePage") {
		setLocationPromptL1.text(shoppingAt);
		setLocationPromptL2.text(zipCode);
		setLocationPromptL1.removeClass("underline");
		setLocationPromptL1.removeClass("fw-700");
		setLocationPromptL1.addClass("pointer-none");
		setLocationPromptL2.addClass("underline");
		setLocationPromptL2.removeClass("pointer-none");
		setLocationPromptL1.removeAttr("data-toggle", "modal");
		setLocationPromptL1.removeAttr("data-target", "#StoreNumberModal");

		setLocationPromptL2.attr("data-toggle", "modal");
		setLocationPromptL2.attr("data-target", "#StoreNumberModal");
		$('.header-zipcode-popup').text(zipCode);
		appendDataByZipcode(zipCode);
	} else {
		window.location.reload(true);
	}
}

function showStoreLocatorPopup() {
	const pageUID = $('#acimaMpPageUID').attr('data-value');
	const validPages = [
		"acimaMarketplacePage",
		"acimaShopOnlinePage",
		"acimaMarketplaceDetailsPage",
		"acimaShopOnlineDetailsPage",
		"acimaFindAStorePage"
	];
	return validPages.includes(pageUID);
}

$(document).ready(function() {
	if(isElemExist('.page-acimaPrivacyPolicyPage')) {
		$('#privacyPdfDownload').on('click', function(e) {
			e.preventDefault();
			let pdfUrl = $(this).data('url');
			handlePrint(pdfUrl);
		});
		async function handlePrint(pdfUrl) {
			try {
				let response = await fetch(pdfUrl);
				let blob = await response.blob();
				let url = URL.createObjectURL(blob);
				let iframe = $('<iframe>', {
					src: url,
					css: { display: 'none' }
				}).appendTo('body');
				iframe.on('load', function () {
					this.contentWindow.print();
				});
			} catch (error) {
				console.error('Error fetching PDF:', error);
			}
		}		
	}
})
function zipCodeModalPopUpResetGlobal() {
	$('#enteredZipCode').val($('.header-zipcode-popup').text().trim());
}